module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3RHBKQ","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change","defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SSLLC: Used lab equipment ","short_name":"SSLLC","start_url":"/","background_color":"#FFFFFF","theme_color":"#166db6","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f305a0a1d4b0ecf2c35f507281404d43"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
